import { isValidlangugage } from './isValidlangugage'

export function extractLanguageFromPathname(pathname: string) {
  const splitPathName = pathname.split('/')

  const language = splitPathName[1]

  if (!isValidlangugage(language)) {
    return { language: undefined, pathnameWithoutLanguage: pathname }
  }
  const pathnameWithoutLanguage = `/${splitPathName.slice(2).join('/')}`

  return { language, pathnameWithoutLanguage }
}
